import React from 'react';
import {
  Fade,
  Grid,
  Typography,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';

import {SplashLogo} from 'shared/assets';

const useStyles = makeStyles((theme: Theme) => {
  return {
    logo: {
      width: '280px',
      position: 'relative',
      transition: 'transform .2s',
      '&:hover': {transform: 'scale(1.25)'},
    },
    bar: {
      width: theme.spacing(35),
      marginTop: theme.spacing(6),
      colorPrimary: theme.palette.primary.main,
    },
    text: {
      marginTop: theme.spacing(3),
      color: theme.palette.text.primary,
    },
  };
});

interface IProps {
  error?: boolean;
  message?: string;
}

export const SplashScreen: React.FC<IProps> = ({
  error = false,
  message = '',
}) => {
  const {bar, logo, text} = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{minHeight: '100vh'}}>
      <Fade in>
        <Grid item>
          <img alt="Logo GVdasa" src={SplashLogo.default} className={logo} />
        </Grid>
      </Fade>
      {error ? (
        <Fade in>
          <Grid item className={text}>
            <Typography variant="subtitle1">
              Erro ao carregar os dados.
            </Typography>
          </Grid>
        </Fade>
      ) : message && message.length > 0 ? (
        <Fade in>
          <Grid item className={text}>
            <Typography variant="subtitle1">{message}</Typography>
          </Grid>
        </Fade>
      ) : (
        <>
          <Grid item>
            <Fade in>
              <LinearProgress className={bar} />
            </Fade>
          </Grid>
          <Fade in>
            <Grid item className={text}>
              <Typography variant="subtitle1">Carregando...</Typography>
            </Grid>
          </Fade>
        </>
      )}
    </Grid>
  );
};
