import {useContext} from 'react';

import {
  ResponseContext,
  ResponseProvider,
  IContext,
} from 'shared/contexts/Response';

function useResponse(): IContext {
  const context = useContext(ResponseContext);

  if (!context) {
    throw new Error('useResponse must be used within an ResponseProvider');
  }

  return context;
}

export {ResponseProvider, useResponse};
