export const createDefaultNameId = (nome: string) => {
  // Remover caracteres não alfabéticos e converter para minúsculas
  nome = nome.replace(/[^a-zA-Z ]/g, '').toLowerCase();

  // Dividir o nome em partes
  const partes = nome.split(' ');

  // Padronizar cada parte
  const partesPadronizadas = partes.map((parte, index) => {
    // Para o primeiro nome, manter em minúsculas
    if (index === 0) {
      return parte;
    } else {
      // Para as outras partes, capitalizar a primeira letra
      return parte.charAt(0).toUpperCase() + parte.slice(1);
    }
  });

  // Juntar as partes padronizadas
  return partesPadronizadas.join('');
};
