export const handleDataFormat = (data: string) => {
  if (!data || data === '0001-01-01 00:00:00.0000000') {
    return 'sem registros';
  }
  const [date, time] = data.split('T');
  const [year, month, day] = date.split('-');
  const [hour, min] = time.split(':');
  const response = `${day}/${month}/${year} - ${hour}:${min}`;
  return response;
};
