import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: 6,
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': theme.palette.primary.main,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#E0E0E0',
        borderRadius: 50,
        width: 12,
        maxHeight: 100,
      },
      'input[type=file]::-webkit-file-upload-button': {
        display: 'none',
      },
    },
    overlay: {
      top: 0,
      zIndex: 999,
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: 'rgb(0 0 0 / 30%)',

      '& > *': {
        display: 'flex',
        position: 'fixed',
        top: 'calc(50% - 25px)',
        left: 'calc(50% - 25px)',
        width: '50px !important',
        height: '50px !important',
      },
    },
    zipCode: {
      float: 'right',
      fontSize: 'smaller',
      marginRight: theme.spacing(1),
    },
  }),
);
