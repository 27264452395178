const stringCapitalize = (str: string, cod: string): string => {
  const aux = str
    .toLowerCase()
    .replaceAll(/[^\w\s]/gi, '')
    .split(' ');
  let strCapitalize = '';

  for (let index = 0; index < aux.length; index += 1) {
    if (index === 0) {
      strCapitalize += aux[index];
    } else {
      strCapitalize = `${strCapitalize}${
        aux[index]?.charAt(0).toUpperCase() + aux[index]?.slice(1)
      }`;
    }
  }

  return strCapitalize + cod;
};

export default stringCapitalize;
