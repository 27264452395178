import {Api} from 'shared/services/axiosConfig';

export interface IAddress {
  cep: string;
  uf: string;
  localidade: string;
  bairro: string;
  logradouro: string;
}

const getAddress = async (cep: string): Promise<IAddress | undefined> => {
  try {
    const response = await Api().get<IAddress>(`/Cep/${cep}`);

    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const AddressService = {
  getAddress,
};
