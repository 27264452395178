import React, {useEffect, useRef} from 'react';
import {TextField as MuiTextField, TextFieldProps} from '@material-ui/core';
import {useField} from '@unform/core';

export const TextField: React.FC<
  TextFieldProps & {
    name: string;
    type?: string;
    helper?: string;
    isError?: boolean;
  }
> = ({name, type, helper, isError, ...rest}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {fieldName, defaultValue, registerField, error} = useField(name);

  useEffect(() => {
    registerField({
      ref: inputRef.current,
      name: fieldName,
      path: type === 'file' ? 'files[0]' : 'value',
    });
  }, [fieldName, registerField, type]);

  return (
    <MuiTextField
      name="outro name"
      {...rest}
      fullWidth
      type={type}
      error={isError ? isError : !!error}
      variant="outlined"
      inputRef={inputRef}
      defaultValue={defaultValue}
      helperText={(!!error && error) || helper}
    />
  );
};
