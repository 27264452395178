import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {FormPage} from 'pages/Form';
import {ConfirmationPage} from 'pages/Confirmation';

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Formulário externo - POST*/}
        <Route path="/formulario/:crypto" element={<FormPage />} />
        {/* Formulário externo - PUT*/}
        <Route path="/formulario/lead/:leadCrypto" element={<FormPage />} />

        {/* Formulário interno - POST*/}
        <Route path="/formulario-interno/:idCampaign" element={<FormPage />} />
        {/* Formulário interno - PUT*/}
        <Route
          path="/formulario-interno-com-respostas/:idCampaign/:idLead"
          element={<FormPage />}
        />
        <Route path="/confirmacao" element={<ConfirmationPage />} />
      </Routes>
    </BrowserRouter>
  );
};
