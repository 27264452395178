export const handleFormatText = (textValue: string, formatType: string) => {
  switch (formatType) {
    case 'uppercase':
      return textValue.toUpperCase();
    case 'lowercase':
      return textValue.toLowerCase();
    case 'titlecase':
      return textValue.replace(/\w\S*/g, (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      });
    case 'sentensecase':
      return textValue.replace(/(^\w{1}|\.\s*\w{1})/gi, (txt: string) => {
        return txt.toUpperCase();
      });

    default:
      return textValue;
  }
};
