export const handleMaxColumns = (value: number) => {
  switch (value) {
    case 1:
      return 12;
    case 2:
      return 6;
    case 3:
      return 4;
    default:
      return 12;
  }
};
