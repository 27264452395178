import {AxiosError} from 'axios';

export enum ESeverityError {
  info = 1,
  success = 2,
  error = 3,
  warning = 4,
}

export interface IErrorMessage {
  campos?: string[];
  mensagem: string;
  mensagemId?: string | number;
  severidade: ESeverityError;
}

export interface IResultOfAction {
  sucesso: boolean;
  mensagens: IErrorMessage[];
  severidade: ESeverityError;
}

export const errorInterceptor = (
  error: AxiosError,
): Promise<IResultOfAction> => {
  const {data} = error.response || {data: undefined};

  if (data) {
    const resultadoDaAcao: IResultOfAction = {
      sucesso: !!data.sucesso,
      mensagens: data || '',
      severidade: data.severidade || ESeverityError.error,
    };

    return Promise.reject(resultadoDaAcao);
  }
  if (error.message === 'Network Error') {
    const resultadoDaAcao: IResultOfAction = {
      sucesso: false,
      severidade: ESeverityError.error,
      mensagens: [
        {
          mensagem: 'Erro de conexão',
          severidade: ESeverityError.error,
        },
      ],
    };

    return Promise.reject(resultadoDaAcao);
  }

  return Promise.reject(error);
};
