import React from 'react';
import {Box, useMediaQuery, useTheme} from '@material-ui/core';

import logo from 'shared/assets/images/logo-gvdasa.png';
import background from 'shared/assets/images/background.jpg';
import {IPersonalization} from 'shared/services/api/FormService';

interface IProps {
  personalization: IPersonalization;
}

export const Header: React.FC<IProps> = ({personalization}) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box position="relative" width="100%">
      <img
        src={
          personalization.bannerParaCabecalhoFormulario
            ? `data:image/${personalization.extensaoBanner};base64,${personalization.bannerParaCabecalhoFormulario}`
            : background
        }
        width="100%"
        alt="banner"
      />
      {personalization.logotipoParaCabecalhoFormulario && (
        <Box
          top={0}
          left={0}
          width="100%"
          height="100%"
          padding={upMd ? 3 : upSm ? 2 : 1}
          position="absolute">
          <img
            src={
              personalization.logotipoParaCabecalhoFormulario
                ? `data:image/${personalization.extensaoLogotipo};base64,${personalization.logotipoParaCabecalhoFormulario}`
                : logo
            }
            alt="logo"
            height="95%"
          />
        </Box>
      )}
    </Box>
  );
};
