import Axios, {AxiosInstance} from 'axios';

import {Environment} from 'shared/env';
import {errorInterceptor, responseInterceptor} from './interceptors';

export const Api = (): AxiosInstance => {
  const config = Axios.create({
    withCredentials: true,
    baseURL: Environment.URL_API,
    headers: window.location.host.includes('localhost') && {
      origin_debug: `${Environment.URL_ORIGIN_DEBUG}`,
    },
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return config;
};
