import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Box, Button, Typography} from '@material-ui/core';

import mail from 'shared/assets/images/mail.jpeg';
import {Header} from 'shared/components';
import {useResponse} from 'shared/hooks';
import {IResponseFormSubmit} from 'shared/services/api/FormService';

export const ConfirmationPage: React.FC = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [paymentResponse, _] = useState<IResponseFormSubmit>(
    state as IResponseFormSubmit,
  );
  const {personalization, crypto, isInternal, isEditing} = useResponse();

  return (
    <Box
      maxWidth="100%"
      height="100vh"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column">
      {!isInternal ? <Header personalization={personalization} /> : <Box />}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gridGap="16px"
        marginTop={2}
        padding={3}>
        <img src={mail} alt="mail" />

        {!isInternal ? (
          personalization.mensagemDeConclusaoFormulario && (
            <Box
              width="100%"
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html:
                  personalization.mensagemDeConclusaoFormulario.replaceAll(
                    'class',
                    'className',
                  ),
              }}
            />
          )
        ) : (
          <Box width="100%" textAlign="center">
            <Typography variant="h6">
              Registro {!isEditing ? 'adicionado' : 'alterado'} com sucesso.
            </Typography>
          </Box>
        )}
      </Box>

      <Box width="100%" padding={3}>
        {!isInternal && paymentResponse && paymentResponse?.linkPagamento && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              window.open(paymentResponse.linkPagamento as string, '_blank')
            }>
            Realizar pagamento
          </Button>
        )}
      </Box>
    </Box>
  );
};
