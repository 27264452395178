import {IconButton, Tooltip} from '@material-ui/core';
import {Info} from '@material-ui/icons';
import React from 'react';

interface IProps {
  title: string;
}

export const InfoTooltip: React.FC<IProps> = ({title}) => {
  return (
    <Tooltip title={title} placement="right">
      <IconButton aria-label={title} size="small" color="primary">
        <Info fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
