/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, useRef} from 'react';
import {TextField as MuiSelect, TextFieldProps} from '@material-ui/core';
import {useField} from '@unform/core';

export const Select: React.FC<
  TextFieldProps & {
    name: string;
    helper?: string;
  }
> = ({name, helper, onChange, children, ...rest}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {fieldName, defaultValue, registerField, error} = useField(name);
  const [selectValue, setValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => selectValue,
      setValue: (_, value) => setValue(value),
    });
  }, [fieldName, selectValue, registerField]);

  const handleChange = (e: any): void => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <MuiSelect
      name={name}
      select
      fullWidth
      {...rest}
      ref={inputRef}
      error={!!error}
      variant="outlined"
      value={selectValue}
      onChange={handleChange}
      defaultValue={defaultValue || ''}
      helperText={(!!error && error) || helper}>
      {children}
    </MuiSelect>
  );
};
