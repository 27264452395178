import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {ptBR} from 'date-fns/locale';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import {ResponseProvider} from 'shared/hooks';

export const AppProvider: React.FC = ({children}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <ResponseProvider>{children}</ResponseProvider>
    </MuiPickersUtilsProvider>
  );
};
