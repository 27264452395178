export const handleFormatInputTextData = (inputData: string) => {
  const [year, month, day] = inputData.split('-');
  const formattedInputData = `${year}-${month}-${day}`;
  if (inputData.length > 10) {
    const formattedInputDataEdited = `${year.substring(
      0,
      year.length - 1,
    )}-${month}-${day}`;
    return formattedInputDataEdited;
  }
  return formattedInputData;
};
